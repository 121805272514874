import React from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "../App.css";
const Preview = ({ Images, removeImageHandler }) => {
  return (
    <div
      className=""
      style={{
        display: "flex",
        flexWrap: "wrap",
        margin: "15px",
      }}
    >
      {Images.map((image, id) => {
        return (
          <div style={{ display: "flex" }} key={id}>
            <div
              onClick={() => removeImageHandler(image.name)}
              style={{ justifyContent: "flex-start" }}
            >
              <FontAwesomeIcon icon={faTimesCircle} size="sm" />
            </div>
            <div>
              {image.type === "application/pdf" ? (
                <DescriptionIcon
                  style={{ fontSize: "60px", marginBottom: "10px" }}
                />
              ) : (
                <img
                  src={URL.createObjectURL(image)}
                  alt="images"
                  style={{
                    width: "80px",
                    height: "80px",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Preview;
