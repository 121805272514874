import React, { Fragment } from "react";
import "./CSS/Navbar.css";
import Ellipse from "./images/Ellipse.png";
import donegif from "./images/donegif.gif";
import { useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import "./CSS/Button.css";
import styles from "./UploadDone.module.css";

const SuccessFulUpload = () => {
  return (
    <Fragment>
      <div>
        <div className={styles.upload_image_container}>
          <img className={styles.done_img} src={donegif} alt="upload image" />
        </div>

        {/* bottom */}
        <div>
          <div className={styles.success}>Upload successful</div>
          <center className={styles.done}>
            Please do bring the referral letter on the day
          </center>
          <center className={styles.done}>of your appointment</center>
          <center style={{ marginTop: "15px" }}>Thank you</center>
        </div>
      </div>
    </Fragment>
  );
};

const UploadErrorInfo = ({ RetryHandler }) => {
  return (
    <Fragment>
      <div className={styles.upload_error_container}>
        <div>
          <h1>201 Error</h1>
          {/* <p> That's an error.</p> */}
          <p style={{ fontSize: "20px" }}>Error:invalid_request</p>
          <p>conversation ID is invalid</p>
          <div>
            <Button style={{ outline: "none" }} onClick={RetryHandler}>
              <KeyboardBackspaceIcon />
            </Button>
            please retry
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const UploadErrorProcessInfo = ({ result, error_code }) => {
  return (
    <Fragment>
      <div className={styles.upload_error_container}>
        {" "}
        <div className={styles.file_process_error}>
          {error_code===101? <h3>101 Error</h3>:""}
          <p>
            We are unable to process the <b>referral form</b>. Please bring it
            along with you during your visit to the center.
          </p>
          <center>Thank you</center>
          {/* <p>{result?.error_description}</p> */}
        </div>
      </div>
    </Fragment>
  );
};

const ThirdPage = () => {
  const reduxState = useSelector((state) => state);
  // const { params } = this.props.match.params.id1;
  // console.log("my params",params)
  const { id1, id2 } = useParams();
  console.log("ID!", id1);
  let result = JSON.parse(id1);
  console.log("RESULT", result);

  // console.log("id1--", id1, id2);
  const history = useHistory();
  const RetryHandler = () => {
    history.push(`/?${id2}`);
  };

  const DoneMessage = () => {

    if (result.error_code === "0") {
      return <SuccessFulUpload />;
    } else if (result.error_code === "201" || result.error_code === "null") {
      return (<UploadErrorInfo RetryHandler={RetryHandler}/>
        
      );
    } else if (result.error_code === "101") {
      return  <UploadErrorProcessInfo result={result}  error_code={101} />
      
    } else if (result === false) {
      return <UploadErrorProcessInfo result={result} />
        
      
    }
  };

  return (
    <Fragment>
      <div className="navbar" style={{ padding: "0px" }}>
        <div className="status_bar"></div>
        <div className="top_nav">
          <img src={Ellipse} alt="ellipse" style={{ marginLeft: "10px" }} />
          <p className="imaging_center">{reduxState.centerName}</p>
        </div>
      </div>
      <div>
        <DoneMessage />
       
      </div>
    </Fragment>
  );
};

export default ThirdPage;
