import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import FirstPage from "./pages/FirstPage";
import ThirdPage from "./pages/ThirdPage";
import Preview from "./pages/Preview";
import "./App.css";
import EmailPage from "./pages/EmailPage";

function App() {
  return (
    <div className="App">
      <Router>
        {/* <FirstPage/> */}
        <Switch>
          <Route exact path="/" component={FirstPage} />
          <Route exact path="/:id" component={FirstPage} />
          <Route exact path="/upload_done/:id1?/:id2?" component={ThirdPage} />
          <Route exact path="/capture_preview" component={Preview} />
          <Route exact path="/email/:customer_id" component={EmailPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
