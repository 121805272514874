import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {
  images: "",
  centerName: "",
};

// action
export const addImages = (images) => ({
  type: "ADD_IMAGES",
  payload: images,
});
export const centerNameFunc = (centerName) => ({
  type: "CENTER_NAME",
  payload: centerName,
});

const myReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_IMAGES":
      return {
        ...state,
        images: action.payload,
      };
    case "CENTER_NAME":
      return {
        ...state,
        centerName: action.payload,
      };
    default:
      return state;
  }
};

const store = createStore(myReducer, composeWithDevTools());

export default store;
