import React, { useState, useEffect } from "react";
import "./CSS/Navbar.css";
import "./CSS/Button.css";
import upload from "./images/upload.png";
import camera from "./images/camera.png";
import midphoto from "./images/midphoto.png";
import Ellipse from "./images/Ellipse.png";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Preview from "./Preview";
import { centerNameFunc } from "../store/store";
import UploadGif from "./images/uploading.gif";
// boxShadow:"2px 4px 4px #ebebf5"
const pageCSS = {
  position: "absolute",
  top: "450px",
  width: "100%",
  height: "29vh",
  justifyContent: "center",
  textAlign: "center",
  flexDirection: "row",
  display: "flex",
  overflow: "scroll",
  boxSizing: "BorderBox",
};
const FirstPage = () => {
  //  const [modalShow, setModalShow] = useState(false);
  const [fileInput, setFileInput] = useState(null);

  const [Uploading, setUploading] = useState(false);

  const dispatch = useDispatch();
  var Searchquery = window.location.search;
  Searchquery = Searchquery.substring(1);

  const history = useHistory();

  const [data, setData] = useState({
    Images: [],
    upload: false,
    photocapture: true,
  });

  let imagesLength = data.Images.length;
  useEffect(() => {
    if (imagesLength === 0) {
      console.log("image File");
    }
  }, [imagesLength]);
  const CaptureHandler = (e) => {
    var fileInput = document.getElementById("file");

    var filePath = fileInput.value;
    // console.log("fileInput---", filePath);

    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;

    //NOTE:- here we are  checking the file type.
    if (!allowedExtensions.exec(filePath)) {
      alert("Please choose image or Pdf");
      fileInput.value = "";
      return;
    }

    const images = Array.from(e.target.files);
    let currentImages = data.Images;
    currentImages = [...currentImages, ...images];
    setData({
      ...data,
      Images: currentImages,
      upload: true,
      photocapture: false,
    });
  };

  var urlParams;
  (window.onpopstate = function () {
    var match,
      pl = /\+/g, // Regex for replacing addition symbol with a space
      search = /([^&=]+)=?([^&]*)/g,
      decode = function (s) {
        return decodeURIComponent(s.replace(pl, " "));
      },
      query = window.location.search.substring(1);

    urlParams = {};
    while ((match = search.exec(query)))
      urlParams[decode(match[1])] = decode(match[2]);
  })();

  //NOTE:- customer coverstaion Id
  const conversation_id = urlParams["conversation_id"];

  //NOTE:-imaging  center name
  let centerName = null;

  centerName = urlParams["center_name"];
  useEffect(() => {
    dispatch(centerNameFunc(urlParams["center_name"]));
  }, []);

  const UploadHandler = async (conversation_id) => {
    if (!data.upload) {
      // history.push(`/?${Searchquery}`);
      alert("please choose a file");
      return;
    }

    var formdata = new FormData();

    if (data.Images.length === 0) {
      alert("please choose a file");
      return;
    }
    for (const file of data.Images) {
      formdata.append("files", file);
    }
    setUploading(true);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    // console.log("ENV FILE", process.env.REACT_APP_API_URL);
    // "https://dev-test.ngrok.io/oi/api/referral/upload/files?conversation_id="
    await fetch(
      `${window.env.API_URL}/oi/api/referral/upload/files?conversation_id=` +
        conversation_id["conversation_id"],
      requestOptions
    )
      .then((response) => {
        // console.log("RESPONSE", typeof response.status);
        if (response.status === 400) {
          history.push("/");
          return;
        }
        return response.text();
      })
      .then((result) => {
        setUploading(false);

        // console.log("this is response", result);
        // console.log("Hello shubham file sent");
        // console.log("SearchQuery", Searchquery);

        history.push(`/upload_done/${result}/${Searchquery}`);
      })
      .catch((error) => {
        // console.log("this is error", error);
        let False = false;
        history.push(`/upload_done/${False}/${Searchquery}`);
      });

    setData({
      ...data,
      Images: [],
      upload: false,
      photocapture: true,
    });
  };

  const removeImageHandler = (name) => {
    console.log("IMAGE ID", name);
    const filteredImage = data.Images.filter((image) => image.name !== name);
    // console.log("filtered image array",images)
    setData({
      ...data,
      Images: filteredImage,
    });
  };
  // console.log("Preview upload: ", data.upload);
  return (
    <div
      className="firstpage"
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
    >
      {/* header */}
      {/* start header */}
      <div className="navbar" style={{ padding: "0px" }}>
        <div>
          <div className="status_bar"></div>
          <div
            className="top_nav"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src={Ellipse} alt="ellipse" style={{ marginLeft: "10px" }} />
            <p className="imaging_center">
              {centerName === "" || centerName === undefined
                ? "Staging Center"
                : centerName}
            </p>
          </div>
        </div>
      </div>
      {/* Mid part */}
      <img className="midpart" src={midphoto} alt="" />
      <div className="" style={{}}>
        <p className="submit_doc">Submit document</p>
        <p className="doc">Kindly take or upload a photo of document </p>
      </div>
      {/* </div> */}
      <button
        className="capture"
        onClick={() => fileInput.click()}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <label>
          <img
            src={camera}
            style={{ marginRight: "10px", outline: "none" }}
            alt="camera"
          />
          Capture
        </label>
        <input
          type="file"
          id="file"
          ref={(fileInputref) => setFileInput(fileInputref)}
          accept="image/png, image/gif, image/jpeg,image/jpg,.pdf"
          //multiple property accepts all kind of input like image , video , doc etc...
          // multiple
          onChange={CaptureHandler}
          style={{ display: "none" }}
        />
      </button>
      <button
        className="upload"
        onClick={() => UploadHandler({ conversation_id })}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          outline: "none",
        }}
      >
        <img
          src={upload}
          style={{ width: "16px", marginRight: "10px" }}
          alt=""
        />{" "}
        Upload
      </button>
      <div>
        <div style={pageCSS}>
          {data.upload && (
            <Preview
              Images={data.Images}
              removeImageHandler={removeImageHandler}
            />
          )}
        </div>
        <div style={{ position: "relative", top: "500px" }}>
          {Uploading && (
            <img
              src={UploadGif}
              alt="Loading .."
              style={{ height: "80px", width: "100%" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
